import React from 'react';

import {List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import SettingsIcon from '@material-ui/icons/Settings';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

import { NavigationSubscribe } from 'spotlio-components';

class MainMenu extends React.Component {

  render() {
    return (
      <NavigationSubscribe>
        {(navigation) => (
        <List>
          <ListItem button onClick={() => navigation.push('/') }>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigation.push('/bookings-schedule') }>
            <ListItemIcon><ConfirmationNumberIcon /></ListItemIcon>
            <ListItemText primary="Bookings schedule" />
          </ListItem>
          <ListItem button onClick={() => navigation.push('/activities') }>
            <ListItemIcon><LocalActivityIcon /></ListItemIcon>
            <ListItemText primary="Manage activities" />
          </ListItem>
          <ListItem button onClick={() => navigation.push('/products') }>
            <ListItemIcon><StoreMallDirectoryIcon /></ListItemIcon>
            <ListItemText primary="Manage products" />
          </ListItem>
          <ListItem button onClick={() => navigation.push('/orders') }>
            <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItem>
          <ListItem button onClick={() => navigation.push('/customers') }>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>
          <ListItem button onClick={() => navigation.push('/reports') }>
            <ListItemIcon><BarChartIcon /></ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>
          <ListItem button onClick={() => navigation.push('/settings') }>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary="Commerce settings" />
          </ListItem>
        </List>
        )}
      </NavigationSubscribe>
    );
  }
}

export default MainMenu;
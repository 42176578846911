import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter, Router } from "react-router-dom";

import 'typeface-roboto';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './App.css';

import Layout from 'app/layout';
import { NavigationSubscribe, NavigationProvider, PromisedComponent } from 'spotlio-components';

function App() {
 
  return (
    <React.Fragment>
      <CssBaseline />
      <PromisedComponent />
      <NavigationProvider>
        <BrowserRouter>
          <NavigationSubscribe>{navigation =>
            <Router history={navigation.history}>
              <Layout></Layout>
            </Router>
          }</NavigationSubscribe>
        </BrowserRouter>
      </NavigationProvider>
    </React.Fragment>
  );
}

export default App;

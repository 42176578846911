import React, { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";

import AppLoader from 'components/loader';
import {CoreSubscribe} from 'services/core';

/* Lazy navigation */
const DashboardView = lazy(() => import('views/dashboard'));

const BookingsScheduleModule = lazy(() => import('modules/bookings-schedule'));
const ProductsModule = lazy(() => import('modules/products'));

// const ProductDetailContainer = lazy(() => import('./views/product-detail'));
// const ProductCreateContainer = lazy(() => import('./views/product-create'));
// const ProductEditContainer = lazy(() => import('./views/product-edit'));

const OrdersContainer = lazy(() => import('views/orders'));
// const CalendarContainer = lazy(() => import('./views/calendar'));
// const CalendarWeekContainer = lazy(() => import('./views/week'));
// const CalendarDayComponent = lazy(() => import('./views/day'));
const NotFoundview = lazy(() => import('views/not-found'));

/*
const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );
}
*/

function AppRouter() {

  return (
    <React.Fragment>
      <CoreSubscribe>
        {core => ( core.state.redirect ? <Redirect to={core.state.redirect}></Redirect> : null )}
      </CoreSubscribe>
      <Suspense fallback={<AppLoader />}>
        <Switch>
          <Route exact path="/" component={DashboardView}></Route>
          <Route path="/bookings-schedule" component={BookingsScheduleModule}></Route>
          { /*
          <Route path="/product/new" component={ProductCreateContainer}></Route>
          <Route exact path="/product/:id" component={ProductDetailContainer}></Route>
          <Route path="/product/:id/edit" component={ProductEditContainer}></Route>
          */ }
          <Route exact path="/products" component={ProductsModule}></Route>
          <Route exact path="/orders" component={OrdersContainer}></Route>
          { /*
          <Route exact path="/calendar" component={CalendarContainer}></Route>
          <Route exact path="/week" component={CalendarWeekContainer}></Route>
          <Route exact path="/day" component={CalendarDayComponent}></Route>
          */ }
          <Route component={NotFoundview}></Route>
        </Switch>
      </Suspense>
    </React.Fragment>
  );
}

export default AppRouter;

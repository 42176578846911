import React from "react";
import { Provider, Subscribe, Container } from "unstated";

export class CoreContainer extends Container {
  state = { redirect: false }
  
  async navigate(url) {
    const delay = (fn) => () => setTimeout(() => fn());
    this.setState({redirect: url}, delay(() => this.setState({redirect: null})));
  }
}

const Core = new CoreContainer();

export const CoreProvider = props => { return <Provider inject={props.inject || [Core]}>{props.children}</Provider>; };
export const CoreSubscribe = props => { return <Subscribe to={props.to || [Core]}>{props.children}</Subscribe>; };
export default Core;

// IMPORT NOTE:
// With the above export structure, we have the ability to
// import like this:

// import Core, {CoreProvider, CoreSubscribe, CoreContainer}

// Core: Singleton Core instance, exported as default.
//      Contains your instantiated .state and methods.

// CoreProvider: Context Provider...
//      Publishes your React Context into the top of the
//      React App into the component tree.

// CoreSubscribe: Context Subsriber...
//      Subscribes to the higher Context from any place
//      lower than the point at which the Context was provided.

// CoreContainer:Context Container Class...
//      Used to instantiate new copy of your service if so desired.
//      Can be used for testing, or subsrcibing your class to a new
//      data source that uses the same data model/methods.
